// Import necessary modules
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

// Set fallback language, and available languages
const fallbackLng = ['en'] // set fallback language to English
const availableLanguages = ['en', 'fr', 'es', 'de'] // set available languages to English, French, and Spanish

// Initialize i18next with modules and configuration options
i18n.use(Backend) // use i18next-xhr-backend to load translations from a backend server
    .use(LanguageDetector) // use i18next-browser-languagedetector to automatically detect the user's language
    .use(initReactI18next) // use initReactI18next to integrate with React components
    .init({
        fallbackLng, // set the fallback language
        detection: {
            checkWhitelist: true, // only detect languages that are whitelisted in the availableLanguages array
        },
        debug: false, // disable debug mode
        whitelist: availableLanguages, // set the available languages
        interpolation: {
            escapeValue: false, // disable escaping of string values
        },
    })

// Export i18n object for use in other modules
export default i18n
