import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './assets/sass/index.scss'

import i18n from './i18n'
import { I18nextProvider } from 'react-i18next'

import LoadingSpinner from './components/LoadingSpinner'

// import Home from './pages/Home/index'
// import Header from './components/Header/index'
// import Footer from './components/Footer/index'

const Home = lazy(() => import('./pages/Home/index'))
const Header = lazy(() => import('./components/Header/index'))
const Footer = lazy(() => import('./components/Footer/index'))

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <I18nextProvider i18n={i18n}>
        <Suspense fallback={<LoadingSpinner fullPage />}>
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />}></Route>
                </Routes>
                <Footer />
            </Router>
        </Suspense>
    </I18nextProvider>
)
